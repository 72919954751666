import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Modal from "react-modal"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CaseStudies = props => {
  const { allContentfulCaseStudy } = props.data

  const { numPages, currentPage } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1
      ? "/casestudies"
      : "/casestudies/" + (currentPage - 1).toString()
  const nextPage = "/casestudies/" + (currentPage + 1).toString()

  const [idx, setIdx] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal(i) {
    setIdx(i)
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }

  Modal.setAppElement("#___gatsby")

  const [isCategory, setIsCategory] = useState(false)
  const [isCountry, setIsCountry] = useState(false)
  const [isYear, setIsYear] = useState(false)
  const [isAdvertiser, setIsAdvertiser] = useState(false)

  const uniqueCat = [
    ...new Set(allContentfulCaseStudy.nodes.map(item => item.category)),
  ]
  const uniqueCountry = [
    ...new Set(allContentfulCaseStudy.nodes.map(item => item.country)),
  ]
  const uniqueYear = [
    ...new Set(allContentfulCaseStudy.nodes.map(item => item.year)),
  ]
  const uniqueAdvertiser = [
    ...new Set(allContentfulCaseStudy.nodes.map(item => item.subtitle)),
  ]

  const [filter, setFilter] = useState("")

  const filteredItems = allContentfulCaseStudy.nodes.filter(
    node =>
      node.category.toLowerCase() === filter.toLowerCase() ||
      node.subtitle.toLowerCase() === filter.toLowerCase() ||
      node.country.toLowerCase() === filter.toLowerCase() ||
      node.year.toLowerCase() === filter.toLowerCase()
  )
  const caseStudies = filteredItems.length
    ? filteredItems
    : allContentfulCaseStudy.nodes
  return (
    <div className="caseStudiesPage">
      <Layout>
        <Seo title="Case Studies" />
        <div className="main_content_wrapper casestudies_blog">
          <div className="obie_awards_blog_section casestudies_blog">
            <div className="container">
              <div className="obie_awards_blog_inner_section">
                <div className="creative_library_dropdown">
                  <div className="common_section_title">
                    <h2>
                      case <span> studies</span>
                    </h2>
                  </div>

                  <div className="drop_down_wrapper">
                    <p className="sortByText"> Sort By:</p>
                    <div className="common_drop_down">
                      <div className="drop_down_title">
                        <a
                          href="#0"
                          className={`drop_down_arrow ${
                            isCategory && "showMenu"
                          }`}
                          onClick={() => {
                            setIsCategory(!isCategory)
                            setFilter("")
                          }}
                        >
                          <span>Category</span>
                          <i className="fa fa-caret-down" aria-hidden="true"></i>
                        </a>
                      </div>
                      <ul
                        className="common_drop_down_list"
                        style={{ display: isCategory && "inline-block" }}
                      >
                        {uniqueCat.map((node, i) => (
                          <li
                            key={i}
                            onClick={() => {
                              setFilter(node)
                              setIsCategory(false)
                            }}
                          >
                            {node}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="common_drop_down">
                      <div className="drop_down_title">
                        <a
                          href="#0"
                          className={`drop_down_arrow ${
                            isAdvertiser && "showMenu"
                          }`}
                          onClick={() => {
                            setIsAdvertiser(!isAdvertiser)
                            setFilter("")
                          }}
                        >
                          <span>Advertiser</span>
                          <i className="fa fa-caret-down" aria-hidden="true"></i>
                        </a>
                      </div>
                      <ul
                        className="common_drop_down_list"
                        style={{ display: isAdvertiser && "inline-block" }}
                      >
                        {uniqueAdvertiser.map((node, i) => (
                          <li
                            key={i}
                            onClick={() => {
                              setFilter(node)
                              setIsAdvertiser(false)
                            }}
                          >
                            {node}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="common_drop_down">
                      <div className="drop_down_title">
                        <a
                          href="#0"
                          className={`drop_down_arrow ${isCountry && "showMenu"}`}
                          onClick={() => {
                            setIsCountry(!isCountry)
                            setFilter("")
                          }}
                        >
                          <span>Country</span>
                          <i className="fa fa-caret-down" aria-hidden="true"></i>
                        </a>
                      </div>
                      <ul
                        className="common_drop_down_list"
                        style={{ display: isCountry && "inline-block" }}
                      >
                        {uniqueCountry.map((node, i) => (
                          <li
                            key={i}
                            onClick={() => {
                              setFilter(node)
                              setIsCountry(false)
                            }}
                          >
                            {node}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="common_drop_down">
                      <div className="drop_down_title">
                        <a
                          href="#0"
                          className={`drop_down_arrow ${isYear && "showMenu"}`}
                          onClick={() => {
                            setIsYear(!isYear)
                            setFilter("")
                          }}
                        >
                          <span>Year</span>
                          <i className="fa fa-caret-down" aria-hidden="true"></i>
                        </a>
                      </div>
                      <ul
                        className="common_drop_down_list"
                        style={{ display: isYear && "inline-block" }}
                      >
                        {uniqueYear.map((node, i) => (
                          <li
                            key={i}
                            onClick={() => {
                              setFilter(node)
                              setIsYear(false)
                            }}
                          >
                            {node}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="obie_awards_blog_row">
                  {caseStudies?.map((cs, i) => (
                    <div className="obie_awards_blog_col" key={cs.id}>
                      <div className="obie_awards_blog_info">
                        <a
                          href="#0"
                          className="oa_blog_img"
                          onClick={() => openModal(i)}
                        >
                          <GatsbyImage
                            image={getImage(cs.image)}
                            alt="casestudies blog"
                            className="slider_images"
                          />
                          <a href="#0" className="awards_btn platinum_color">
                            {cs.year}
                          </a>
                        </a>
                        <div className="oa_blog_details">
                          <span
                            className="oa_blog_sub_text"
                            onClick={() => openModal(i)}
                          >
                            {cs.category}
                          </span>
                          <h3
                            className="oa_blog_title"
                            onClick={() => openModal(i)}
                          >
                            {cs.title}
                          </h3>
                          <a
                            href="#0"
                            className="see_details_btn slider_images"
                            onClick={() => openModal(i)}
                          >
                            View More
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>

                      {modalIsOpen && (
                        <Modal
                          isOpen={idx === i}
                          onRequestClose={closeModal}
                          contentLabel={i.toString()}
                          id={i.toString()}
                          key={i}
                          testId={i.toString()}
                          bodyOpenClassName={`caseStudiesModal modalOpen`}
                        >
                          <div className="modal-content">
                            <div className="modal_close_btn">
                              <span onClick={closeModal}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.688"
                                  height="17.688"
                                  viewBox="0 0 17.688 17.688"
                                >
                                  <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                            <div className="modal_body">
                              <div className="casestudy-modal-content">
                                <div className="country_details_wrap">
                                  <div className="country_details">
                                    <h1>{cs.title}</h1>
                                    <p>{cs.subtitle}</p>
                                  </div>
                                  <div className="country_years">
                                    <span>{cs.year}</span>
                                  </div>
                                </div>
                                <div className="modal_inner_img_wrap">
                                  <GatsbyImage
                                    image={getImage(cs.image)}
                                    alt="modal"
                                  />
                                  <div className="oa_blog_winner">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.354"
                                      height="15.77"
                                      viewBox="0 0 14.354 15.77"
                                    >
                                      <path
                                        id="Icon_awesome-canadian-maple-leaf"
                                        data-name="Icon awesome-canadian-maple-leaf"
                                        d="M12.73,10.833c.077-.077,3.24-2.846,3.24-2.846l-.539-.231c-.308-.151-.228-.354-.154-.536.074-.234.619-2.073.619-2.073s-1.469.308-1.777.385c-.231.074-.308-.077-.385-.231s-.462-1-.462-1-1.62,1.845-1.7,1.919c-.308.231-.619,0-.542-.308,0-.308.85-3.992.85-3.992s-.927.536-1.235.69c-.231.154-.388.154-.542-.154C9.949,2.227,8.791,0,8.791,0S7.636,2.227,7.482,2.458c-.154.308-.308.308-.542.154-.308-.154-1.235-.69-1.235-.69s.85,3.684.85,3.992c.077.308-.231.539-.542.308-.077-.077-1.7-1.919-1.7-1.919s-.385.841-.462.995-.154.3-.385.231c-.311-.077-1.777-.385-1.777-.385s.542,1.839.619,2.073c.074.185.154.385-.154.536l-.539.234s3.16,2.769,3.24,2.846c.157.154.308.231.157.693S4.7,12.607,4.7,12.607s2.932-.619,3.243-.7c.268-.028.564.077.564.385s-.179,3.474-.179,3.474h.924s-.179-3.163-.179-3.474.293-.413.567-.385c.308.077,3.24.7,3.24.7s-.154-.619-.308-1.081,0-.539.154-.693Z"
                                        transform="translate(-1.617)"
                                        fill="#fff"
                                      ></path>
                                    </svg>
                                    <p className="oa_blog_winner_text">
                                      {cs.imageText}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="obie_modal_content">
                                <div className="modal_source_wrap">
                                  <div className="modal_source_info">
                                    <p>Source:</p>
                                  </div>
                                  <div className="modal_obie_awards_details">
                                    <p>{cs.source}</p>
                                  </div>
                                </div>
                                <div className="modal_source_wrap">
                                  <div className="modal_source_info">
                                    <p>Product Category:</p>
                                  </div>
                                  <div className="modal_obie_awards_details">
                                    <p>{cs.category}</p>
                                  </div>
                                </div>
                                <div className="modal_source_wrap">
                                  <div className="modal_source_info">
                                    <p>Creative Award:</p>
                                  </div>
                                  <div className="modal_obie_awards_details">
                                    <span className="modal_dots bronze_color">
                                      {" "}
                                    </span>
                                    <p>{cs.award}</p>
                                  </div>
                                </div>
                                <div className="modal_source_wrap">
                                  <div className="modal_source_info">
                                    <p>Country:</p>
                                  </div>
                                  <div className="modal_obie_awards_details">
                                    <p>{cs.country}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="casestudy-modal-content main">
                                <div className="">
                                  {renderRichText(cs.content)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="obie_awards_pagination_wrap">
                <div className="common_pagination_wrap">
                  {!isFirst && (
                    <Link to={prevPage} rel="prev" className="pagination_next">
                      <i className="fa fa-caret-left" aria-hidden="true"></i>
                    </Link>
                  )}
                  <ul className="common_pagination">
                    {Array.from({ length: numPages }, (_, i) => (
                      <li key={`pagination-number${i + 1}`}>
                        <Link
                          to={`${
                            i === 0 ? "/casestudies/" : "/casestudies/" + (i + 1)
                          }`}
                        >
                          <button
                            type="button"
                            className={i + 1 === currentPage && "active"}
                          >
                            {i + 1}
                          </button>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  {!isLast && (
                    <Link to={nextPage} rel="next" className="pagination_next">
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default CaseStudies

export const caseStudies = graphql`
  query caseStudies($skip: Int!, $limit: Int!) {
    allContentfulCaseStudy(limit: $limit, skip: $skip) {
      nodes {
        id
        image {
          gatsbyImageData
        }
        imageText
        title
        year
        subtitle
        category
        source
        award
        country
        content {
          raw
        }
      }
    }
  }
`
